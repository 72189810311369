<template>
  <header-t></header-t>
  <div class=" nPage usHomePage" style="display:">
    <div class="banner">
      <div class="nW Mt24" style="display:none;">
        <img src="@/assets/banner2.jpg" style="widht:100%" />
      </div>
    </div>
    <div class="nW usHomeMain">
      <div class="usNmBox ln">
        <us-home-menu></us-home-menu>
      </div>
      <div class="usHallBox " style="">
        <div class="uHomeTopTj">
          <dl>
            积分总数：<strong>{{ jf.integral }}</strong
            >{{ taskList }}
          </dl>
          <dl>
            冻结积分：<strong>{{ jf.freeze }}</strong>
          </dl>
          <p>
            <el-button type="primary" @click="goPage('/chongzhi')"
              >充值</el-button
            >

            <el-button type="warning" @click="goPage('/tixian')"
              >提现</el-button
            >
          </p>
        </div>
        <router-view />
      </div>
    </div>
  </div>
  <footer-s></footer-s>
</template>

<script setup>
import { ref } from 'vue'
// import { getUsIntegralNum } from '@/api/user'
import { sign, getItem, setItem } from '@/utils/storage'
import HeaderT from '@/components/Public/Header'
import FooterS from '@/components/Public/Footer'
import UsHomeMenu from '@/components/Public/UsHomeMenu'
import { getUserInfo } from '@/api/user'
// import { sign, setItem } from '@/utils/storage'
import { useRouter } from 'vue-router'
const router = useRouter()

const usinfo = getItem('userinfo')
console.log('user-index--usinfo=', usinfo)
if (usinfo.name === '' || usinfo.name === null) {
  router.push('/personalInformation')
}

// 列表
const jf = ref({ integral: usinfo.integral, freeze: usinfo.freeze })
// const getData = async () => {
//   // var ijf = await getUsIntegralNum(sign({ jf: 'us' }))
//   // var ius = getItem('userinfo')
//   // ius.integral = parseInt(ijf.integral)
//   // ius.freeze = parseInt(ijf.freeze)
//   // setItem('userinfo', ius)
//   jf.value = { integral: usinfo.integral, freeze: usinfo.freeze }
// }
// getData()

// 刷新用户信息
// console.log(setItem, getUserInfo)
const refreshUserInfo = async () => {
  var res = await getUserInfo(sign({ tg: 'ok' }))

  // console.log('用户-', res)
  res.freeze = parseInt(res.freeze)
  res.integral = parseInt(res.integral)
  jf.value = { integral: res.integral, freeze: res.freeze }
  setItem('userinfo', res)
}
refreshUserInfo()

const goPage = ss => {
  router.push(ss)
}
</script>

<style lang="scss" scoped></style>
